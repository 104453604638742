var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        { attrs: { "data-cy": "expand" } },
                        [
                          _vm._v(" Search "),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "2px" } },
                            [_vm._v("(" + _vm._s(_vm.totalRecord) + ")")]
                          ),
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    ref: "name",
                                    attrs: {
                                      "data-cy": "name",
                                      label: "Name",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllCarrier()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "name", $$v)
                                      },
                                      expression: "searchParam.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("carrier-sales-rep-auto-complete", {
                                    attrs: {
                                      "data-cy": "carrier-manager",
                                      "default-carrier-sales-rep":
                                        _vm.searchParam.carrierManager,
                                    },
                                    on: { event: _vm.selectedCarrierManager },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    ref: "city",
                                    attrs: {
                                      "data-cy": "city",
                                      label: "City",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllCarrier()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.city,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "city", $$v)
                                      },
                                      expression: "searchParam.city",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    ref: "state",
                                    attrs: {
                                      "data-cy": "state",
                                      hint: "Short state name (Ex. Texas - TX)",
                                      label: "State",
                                      "persistent-hint": "",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllCarrier()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.state,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "state", $$v)
                                      },
                                      expression: "searchParam.state",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    ref: "mc-number",
                                    attrs: {
                                      "data-cy": "mc-number",
                                      label: "MC Number",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllCarrier()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.mcNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "mcNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.mcNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    ref: "dot-number",
                                    attrs: {
                                      "data-cy": "dot-number",
                                      label: "DOT Number",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllCarrier()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.dotNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "dotNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.dotNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2", sm: "6" } },
                                [
                                  _c("v-text-field", {
                                    ref: "scac",
                                    attrs: {
                                      "data-cy": "scac",
                                      label: "SCAC",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllCarrier()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.scac,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "scac", $$v)
                                      },
                                      expression: "searchParam.scac",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2", sm: "6" } },
                                [
                                  _c("v-select", {
                                    ref: "approved",
                                    attrs: {
                                      items: _vm.carrierApproved,
                                      "data-cy": "approved",
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Approved",
                                    },
                                    model: {
                                      value: _vm.searchParam.approved,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "approved",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.approved",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3", sm: "6" } },
                                [
                                  _c("v-select", {
                                    ref: "status",
                                    attrs: {
                                      items: _vm.carrierStatusAll,
                                      "data-cy": "status",
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Status",
                                    },
                                    model: {
                                      value: _vm.searchParam.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "status", $$v)
                                      },
                                      expression: "searchParam.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "xs",
                                  attrs: { cols: "12", md: "3", sm: "4" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blue white--text dark-3",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchAllCarrier()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-search-web "),
                                      ]),
                                      _vm._v(" Filter "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetSearch()
                                        },
                                      },
                                    },
                                    [_vm._v(" Reset ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0 data-container",
              attrs: { id: "tblCarrier", fluid: "" },
            },
            [
              _c("v-data-table", {
                ref: "table",
                staticClass: "elevation-2 data-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.carrierList,
                  "items-per-page": _vm.pageSize,
                  loading: _vm.loadingIcon,
                  "calculate-widths": "",
                  "fixed-header": "",
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "name-link",
                            attrs: {
                              to: {
                                name: "Carrier Detail",
                                params: { id: item.id },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("v-page", {
                          ref: "table-footer",
                          staticClass: "pt-3 pr-3 pb-3",
                          attrs: {
                            "page-size-menu": [10, 20, 50, 100],
                            "total-row": _vm.totalRecord,
                          },
                          on: { "page-change": _vm.readDataFromAPI },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }